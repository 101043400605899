import { StyleDinamic } from './styleDinamic';
import { useEffect, useState } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, listAll } from 'firebase/storage';
import { db } from '../../lib/firebase';
import useUser from '../../hooks/use-user';
import { useAppSelector } from '../../redux/actions';
import { Link } from 'react-router-dom';

import Hearts from '../Hearts/Hearts';

export default function DinamicVlog() {
  const [article, setArticle] = useState<any[] | null>(null);

  const user = useAppSelector((state) => state.logUser.value);
  const {
    userdb: { docId, likedArticles },
  } = useUser();

  useEffect(() => {
    const storage = getStorage();

    const imageRefList = ref(storage, 'articles/');
    const response = async () => {
      try {
        const resp = await listAll(imageRefList);
        return resp;
      } catch (error: any) {
        console.error('error', error.message);
      }
    };
    response();
  }, []);

  useEffect(() => {
    const q = query(collection(db, 'articles'), where('author', '!=', false));

    onSnapshot(q, (querySnapShot) => {
      const art: any = [];

      querySnapShot.forEach((doc) => {
        art.push(doc.data());
      });

      setArticle(art);
    });
  }, []);

  return (
    <StyleDinamic>
      <div className="newsDinamic">
        {user ? (
          <>
            {article !== null
              ? article?.map((item: any) => (
                  <article className="article-news" key={item.id}>
                    <div>
                      <b className="article-news--uppercase">{item.title}</b>

                      <br />

                      <img
                        src={`https://firebasestorage.googleapis.com/v0/b/whitehat4u-d7536.appspot.com/o/articles%2F${item.cover}?alt=media&token=${process.env.REACT_APP_FIREBASE_STORAGE_TOKEN}`}
                        alt=""
                        className="article-news__images"
                        loading="lazy"
                      />

                      <Hearts
                        ida={item.id}
                        docId={docId}
                        likedArticles={likedArticles}
                      />
                    </div>

                    <div className="info-news">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${item.content}`,
                        }}
                        className="article-content"></div>
                      <Link to={`../news/${item.id}`}>
                        <button className="article-button">Read More</button>
                      </Link>
                    </div>
                  </article>
                ))
              : 'loading ... '}
          </>
        ) : (
          ' To read more articles please register'
        )}
      </div>
    </StyleDinamic>
  );
}
