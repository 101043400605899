import styled from 'styled-components';

export const HeaderStyle = styled.div`
  background-color: transparent;

  font-family: ${(props) => props?.theme.fonts.secondary};
  .top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;

    &__future {
      color: ${(props) => props?.theme.colors.black};
    }

    &__logoPlace {
      width: 80%;
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (min-width: 1030px) {
    .top {
      width: 1030px;
      margin: auto;
      display: flex;
      flex-direction: column;

      &__logoPlace {
        width: 240px;
        display: flex;
        align-self: flex-start;
      }

       &__future {
        display: flex;
        align-self: flex-start;
      }
    }
  }
`;
