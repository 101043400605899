import styled from 'styled-components';

export const LayoutStyle = styled.div`
  background-image: url('/textureMedium.jpeg');
  background-repeat: repeat;

  /* background: ${(props) => props?.theme.colors.background}; */
  .content {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: 1030px) {
    .content {
      width: 1030px;
      margin: auto;
      display: flex;
      justify-content: flex-start;
      padding: 0%;
    }
  }
`;
