import React, { useEffect, useState } from 'react';
import { TheNews } from './publicNewStyle';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, listAll } from 'firebase/storage';
import { db } from '../../lib/firebase';
import { PubicNews } from '../../@types/types';
import { Link } from 'react-router-dom';
import { createComponent } from '@lit/react';
import { Button } from '@juanpumpkinpie/gsx/dist/my-button.js';

type MyElementComponentProps = any;

export const MyElementComponent = createComponent<MyElementComponentProps>({
  tagName: 'my-button',
  elementClass: Button,
  react: React,
});

export default function PublicVlog() {
  const [article, setArticle] = useState<PubicNews[]>([]);

  useEffect(() => {
    const storage = getStorage();

    const imageRefList = ref(storage, 'articles/');
    const response = async () => {
      try {
        const resp = await listAll(imageRefList);
        return resp;
      } catch (error: any) {
        console.error('error', error.message);
      }
    };
    response();
  }, []);

  useEffect(() => {
    const q = query(collection(db, 'articles'), where('public', '!=', false));
    onSnapshot(q, (querySnapShot) => {
      const art: PubicNews[] = [];

      querySnapShot.forEach((doc) => {
        art.push(doc.data() as PubicNews);
      });

      setArticle(art);
    });

    return () => {
      setArticle([]);
    };
  }, []);

  article.sort((a, b) => {
    if (a.id < b.id) return 1;
    if (a.id > b.id) return -1;
    return 0;
  });

  const latestArticles = article.slice(0, 3);

  return (
    <TheNews>
      <div className="news">
        {latestArticles.length !== 0
          ? latestArticles.map((item) => (
              <article
                className="news__article"
                key={`${item.id}-${item.title}`}>
                <div>
                  <b className="news__title">
                    <span>▲</span> {item.title}
                  </b>
                  <aside>
                    <mark> {item.author} </mark>| <mark> News </mark>
                  </aside>
                  <br />
                  <span className="news__tag">{item.id}</span>
                  <Link to={`news/${item.id}`}>
                    <img
                      src={`https://firebasestorage.googleapis.com/v0/b/whitehat4u-d7536.appspot.com/o/articles%2F${item.cover}?alt=media&token=de7011a0-bc3f-46ea-adee-daf5ba9a6f42`}
                      alt="whitehat4u-vlog"
                      className="news__imageNews"
                      fetchpriority="high"
                      loading="lazy"
                    />
                  </Link>
                </div>

                <div className="news__section">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${item.content.slice(0, 300)}`,
                    }}
                    className="news-content"
                    key={`${item.id}-${item.title}`}></div>
                  <Link to={`news/${item.id}`}>
                    <div className="news-content__button">
                      <MyElementComponent
                        key={item.id}
                        labelTemplate="Read More"
                        colors="wh4u"
                        type="button"></MyElementComponent>
                    </div>
                  </Link>
                </div>
              </article>
            ))
          : 'Loading'}
      </div>
    </TheNews>
  );
}
