import styled from 'styled-components';

export const LearnStyle = styled.div`
  position: relative;
  background-image: url('/textureMedium.jpeg');
  background-repeat: repeat;

  .button-return {
    cursor: pointer;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 2;
  }

  .container-dashboard {
    display: flex;
    justify-content: center;
    max-width: 100%;
    margin: auto;
    background-color: ${(props) => props?.theme.colors['o-soft']};
    color: ${(props) => props?.theme.colors['white']};
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  .label {
    padding-left: 20px;
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.tablet}) {
    .button-return {
      cursor: pointer;
      position: fixed;
      right: 20px;
      top: 20px;
      z-index: 2;
    }

    .label {
      padding-left: 0px;
    }
  }
`;
