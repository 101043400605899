import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/actions';
import generatePresignedUrl from '../../lib/aws-digitalOcean';
import { SideBarStyles } from './SideBarStyles';
import { Button } from '@juanpumpkinpie/gsx/dist/my-button.js';
import { createComponent } from '@lit/react';
import useUser from '../../hooks/use-user';

type MyElementComponentProps = any;

export const MyElementComponent = createComponent<MyElementComponentProps>({
  tagName: 'my-button',
  elementClass: Button,
  react: React,
});

interface Item {
  id: number;
  lesson: string;
  description: string;
  linkId: string;
  videoUrl?: string | null;
  dir: string; // Añade el directorio como parte de la interfaz
}
const SideBar: React.FC = () => {
  const [visible, setVisible] = useState(3);
  const [buttonHide, setButtonHide] = useState(false);
  const selectedWatching = useAppSelector((state) => state.logUser.watching);
  const [loading, setLoading] = React.useState(true);
  const [selectedWatchingData, setSelectedWatchingData] = React.useState<
    Item[]
  >([]);
  const [selected, setSelected] = React.useState<Item | undefined>(undefined);
  const [isValid, setIsvalid] = useState<boolean>(false);

  const handleRContext = (event: React.MouseEvent<HTMLVideoElement>) => {
    event.preventDefault();
  };

  const {
    userdb: { registeredOn },
    loading: userLoading,
  } = useUser();

  useEffect(() => {
    if (registeredOn !== undefined) {
      let verified = registeredOn.includes(selectedWatching);

      setIsvalid(verified);
    }
  }, [registeredOn, selectedWatching]);

  useEffect(() => {
    const fectData = async () => {
      try {
        const { program } = await import(
          `../../pages/api/programs/${selectedWatching}.tsx`
        );

        // Generar la URL pre-firmada para el primer video
        const firstItem = program[0];
        const videoUrl = await generatePresignedUrl({
          videoKey: firstItem.linkId,
          dir: firstItem.dir,
        });

        // Actualizar el estado con el primer video y su URL
        setSelectedWatchingData(program);
        setSelected({ ...firstItem, videoUrl: videoUrl || '' }); // Asignar un valor por defecto si videoUrl es null
        setLoading(false);
      } catch (error) {
        setLoading(true);
      }
    };

    fectData();
  }, [selectedWatching]);

  const loadMore = () => {
    const maxData = selectedWatchingData.length;
    setVisible((prev) => prev + 5);
    if (visible >= maxData) {
      setVisible(maxData);
      setButtonHide(true);
    }
  };

  const gotheVideo = async (e: React.MouseEvent, item: Item) => {
    e.preventDefault(); // Add this line
    e.stopPropagation(); // And this line

    window.scrollTo(0, 0);
    // Generate the pre-signed URL for the selected video
    const videoUrl = await generatePresignedUrl({
      videoKey: item.linkId, // Nombre del archivo
      dir: item.dir, // Directorio del archivo
    });

    // Update the selected item with the generated URL
    setSelected({ ...item, videoUrl });
  };

  return (
    <SideBarStyles>
      {!loading ? (
        <>
          {selected !== undefined && isValid && !userLoading ? (
            <>
              <div className="videoSelected">
                <video
                  key={selected.linkId}
                  controls
                  className="videoProjector"
                  controlsList="nodownload"
                  onContextMenu={handleRContext}>
                  {selected.videoUrl ? ( // Solo renderiza el source si videoUrl tiene un valor
                    <source src={selected.videoUrl} type="video/mp4" />
                  ) : (
                    <p>Video no disponible.</p> // Mensaje de error o carga
                  )}
                  Your browser does not support the video tag, please use Chrome
                  instead.
                </video>

                <div className="videoSelected__info">
                  <h2>{selected.lesson}</h2>
                  <p>{selected.description}</p>
                </div>
              </div>

              <>
                {selectedWatchingData.slice(0, visible).map((item) => (
                  <div key={item.lesson} className="sidebar-container">
                    <div
                      className={`sidebar-container--id ${
                        selected.id === item.id ? 'current' : ''
                      }`}>
                      0{item.id}
                    </div>
                    <div className="menu-item-a">
                      <h3> {item.lesson} </h3>
                      <div className="sidebar-container--description">
                        description:
                      </div>
                      {item.description}
                    </div>

                    <div className="menu-item-b">
                      <div className="menu-item-b__content">
                        <div onClick={(e) => gotheVideo(e, item)}>
                          {item.id === selected.id ? (
                            ''
                          ) : (
                            <MyElementComponent
                              key={item.id}
                              labelTemplate="Watch video"
                              colors="wh4u"
                              type="button"></MyElementComponent>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <>
                  {!buttonHide && (
                    <div onClick={loadMore} className="loadMore">
                      <MyElementComponent
                        labelTemplate="Load more videos"
                        colors="wh4u"
                        type="button"></MyElementComponent>
                    </div>
                  )}
                </>
              </>
            </>
          ) : (
            <div className="notPermited">
              <h2>You need purchase the program in order to access it.</h2>
            </div>
          )}
        </>
      ) : (
        'loading ....'
      )}
    </SideBarStyles>
  );
};

export default SideBar;
